$(
    function() {
        app.ip.init();
    }
);
app.ip = {
    _vars: {
        lKey: 'udu__ip-info',
        evtName: 'udu_IP-INFO-RECEIVED',
        inProgress: {

        },
        callBacks: {
            
        }
    },
    init: function() {
        app.ip.getIpInfo();
    },
    getIpInfo: function(callBack, ip) {
        if (!ip) {
            ip = 'cur';
        }
        app.ip._vars.ip = ip;
        var savedData = null;
        var ipData = app.getLData(app.ip._vars.lKey);
        if (ipData && ipData[ip]) {
            savedData = ipData[ip];
        }

        if (savedData) {
            app.events.trigger(app.ip._vars.evtName, {ipInfo: savedData});
            if (callBack) {
                callBack(savedData);
            }
        } else {
            if (app.ip._vars.inProgress[app.ip._vars.ip]) {
                return false;
            }
            app.ip._vars.inProgress[app.ip._vars.ip] = true;
            app.ip._vars.callBacks[app.ip._vars.ip] = callBack;
            var apiKeys = [
                'OARjNzA9VL9NrBWfYPYn',
                'dGeSeqQhBu3RJaloTq9A'
            ];
            var apiKey = apiKeys[Math.floor(Math.random() * apiKeys.length)];
            if (app.ip._vars.ip !== 'cur') {
                $('head').append('<script src="//extreme-ip-lookup.com/json/' + app.ip._vars.ip + '?callback=app.ip.callBack&key=' + apiKey + '" async defer></script>')
            } else {
                $('head').append('<script src="//extreme-ip-lookup.com/json/?callback=app.ip.callBack&key=' + apiKey + '" async defer></script>')
            }
        }
    },
    callBack: function(json) {
        delete app.ip._vars.inProgress[app.ip._vars.ip];
		var ipData = app.getLData(app.ip._vars.lKey);
		if (!ipData) {
			ipData = {};
		}
		ipData[app.ip._vars.ip] = json;
		app.setLData(app.ip._vars.lKey, ipData);
		app.events.trigger(app.ip._vars.evtName, {ipInfo: json, err: false});
		if (app.ip._vars.callBacks[app.ip._vars.ip]) {
			app.ip._vars.callBacks[app.ip._vars.ip](json);
		}
    }
};