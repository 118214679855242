	$(
		 function() {
			 app.assignNumberControl();
             app.attachSvgEvents();
			 app.setSelectedLink();
			 app.addRegisterCheckBoxes();
			 app.makeSocialButtons();
             app.addCoverSpan();
			 app.setBackUrls();
			 app.saveProductListInfo();
             app.topMenu.init();
             app.buildPaginFilter();
             app.wrapMultiInputs();
             app.initACBL();
             app.hamburger.init({mode: 2});
             app.initBMO();
             app.markProductsWithIcons();
             app.initializeScrollButton();
             $('a.fa.my-cart').click(
                 function (e) {
                     app.showMenuPopup(this);app.getCartHtml();
                     e.preventDefault();
                 }
             );
             app.events.addListener(
             	function() {
                    $('li.costcenter .edit-link').addClass('not-set');
				},
				'udu_CHECKOUT-INITIALIZED'
			 );
             app.events.addListener(
             	function() {
                    $('li.costcenter .edit-link').addClass('not-set');
				},
			 	'udu_PAYMENT-METHOD-SAVED'
			 );
             app.events.addListener(
             	function(e) {
             		app.initGlCodes();
				},
				'udu_PAYMENT-POPUP-DONE'
			 );
             app.events.addListener(
             	function(e) {
					var url = e.data.url;
					if (url.indexOf('/CostCenter/') > 0) {
						e.data.url = app.ajaxProcessURLs.process.split('/ajax-process.html')[0] + '/sms-gl-code-payments.html';
						e.data.cssClass += ' sms-gl-codes-payments';
						e.data.onSave = function() {
							app.saveGlCodes();
						};
						app._vars.constCenterURL = url;
					}
				},
				'udu_PAYMENT-EDIT-LINK'
			 );
             app.events.addListener(
             	function(e) {
             		if (e.data.method === 'CostCenter') {
						$('textarea.gl-code-info').attr('disabled', false);
					} else {
                        $('textarea.gl-code-info').attr('disabled', true);
					}
				},
				'udu_BEFORE-PAYMENT-FORM-POST'
			 );

             app.events.addListener(
             	function(e) {
             		if (e.data.method === 'CostCenter') {
						$('textarea.gl-code-info').attr('disabled', false);
					} else {
                        $('textarea.gl-code-info').attr('disabled', true);
					}
				},
				'udu_ON-PAYMENT-FORM-SUBMIT'
			 );
             var $frm = $('form.order-form');
             if ($frm.length > 0) {
             	var html = app.getLData('sms_GlCodesInfo');
             	if (html) {
             		$frm.append(html);
				}
			 }
         }
	);
	var app = {
		_VERSION: 2,
		_vars: {
            _reg_Incorrect_Domain_Mdg: '_reg_Incorrect_Domain_Mdg_BMO',
            _reg_Incorrect_Domain_Extra: '@uducat.com, @cottoncandy.com',
            _reg_doNotShow_placeHolder: true,
			v: 8,
			_guid: "748a1cf6-955d-4bff-94c1-639a4d6c2504"
		},
		messages : {},
		productAvailableQuantityUrl : null,
        _doNotRemoveEmptyMaxQntyForMultipleOrdering: true,
        saveGlCodes: function() {
            $('.sms-gl-wrapper select, .sms-gl-wrapper input').removeClass('with-error');
			var $sel = $('.sms-gl-info-line.gl-visible select, .sms-gl-info-line:first-child select');
			var $inp = $('.sms-gl-info-line.gl-visible input, .sms-gl-info-line:first-child input');
			var $msg = $('.sms-gl__message').addClass('hidden');
			$sel.each(
				function() {
					var $this = $(this);
					if ($this.val() === '') {
						$this.addClass('with-error');
					}
				}
			);
			if ($('select.with-error').length > 0) {
				var msg =  app.lang.selectWord(
					'You have not entered all of the required information.',
					'Vous n\'avez pas fourni tous les renseignements requis.'
				);
				$msg.removeClass('hidden').html(msg);
				return false;
			}

			var prc = 0;
            $inp.each(
            	function() {
            		var $this = $(this);
            		if (Number($this.val())) {
            			prc += Number($this.val());
					}
				}
			);

            if (prc !== 100) {
            	msg = app.lang.selectWord(
            		'The sum of the values in the % field should be 100%. Your total is ' + prc + '%.',
            		'La somme des valeurs dans le champ % doit être 100%. Votre total est de ' + prc + '%.'
				);
                $msg.removeClass('hidden').html(msg);
                $inp.addClass('with-error');
                return false;
			}

			var $form = $('form.order-form');
            $form.find('textarea.gl-code-info').remove();

            var pos = 1;
            var glCode = null;
            var allHtml = '';
            $('.sms-gl-info-line.gl-visible, .sms-gl-info-line:first-child').each(
            	function() {
            		var $this = $(this);
            		var tmpCode = $this.find('select.gl-code').val();
            		var tmpBranch = $this.find('select.gl-branch').val();
            		var tmpDep =$this.find('select.gl-dep').val();

					var html = '<textarea class="gl-code-info hidden" name="custom[gl_code-' + pos + ']">';
					html += 'GL Code: ' + tmpCode + '; ';
					html += 'Branch: ' + tmpBranch + '; ';
					html += 'Department: ' + tmpDep + '; ';
					html += 'Percent: ' + $this.find('input').val() + '; ';
					html += '</textarea>';
					$form.append(html);
					allHtml += html;
					if (!glCode && parseInt(tmpCode)) {
                        glCode = parseInt(tmpCode);
                        if (parseInt(tmpBranch)) {
                        	glCode += '-' + parseInt(tmpBranch);
						}
                        if (parseInt(tmpDep)) {
                            glCode += '-' + parseInt(tmpDep);
                        }
					}
					pos++;
				}
			);

            app.setLData('sms_GlCodesInfo', allHtml);

            /*
            if (parseInt(glCode) > 0) {
                glCode = parseInt(glCode);
			}
			*/

            $('.form-wrapper.gl-codes input').val(glCode);

            app.savePaymentMethodSettings();
		},
		initGlCodes: function() {
			$('li.costcenter .edit-link').addClass('not-set');
			var $sel = $('.sms-gl-wrapper select.sms-gl-count');
			if ($sel.length === 0) {
				return false;
			}
			$('.form-wrapper.gl-codes form').data('udu-action', app._vars.constCenterURL);
			$sel.change(
				function() {
                    $('.sms-gl__message').addClass('hidden');
					$('.sms-gl-wrapper select, .sms-gl-wrapper input').removeClass('with-error');
					var val = $(this).val();
					for (var i = 2; i <= 10; i++) {
						var $item = $('.sms-gl-line-' + i);
						if (i <= val) {
							$item.addClass('gl-visible');
						} else {
                            $item.removeClass('gl-visible');
                            $item.find('input, select').val('');
						}
					}
				}
			);

			$('.sms-gl-info-wrapper input.gl-prc').change(
				function() {
					var $items = $('.sms-gl-info-wrapper .sms-gl-info-line:visible input.gl-prc');
					var sum = 0;
					$items.each(
						function() {
							var $this = $(this);
							console.log(Number($this.val()));
							if (!Number($this.val())) {
								sum = 999999999;
								return false;
							} else {
								sum += Number($this.val());
							}
						}
					);
					if (sum < 100) {
                        var $sel = $('.sms-gl-wrapper select.sms-gl-count');
                        var val = Number($sel.val());
                        if (val < 10) {
                        	val++;
                        	$sel.val(val).change();
						}
					}
				}
			);

			$('.sms-gl-info-line select, .sms-gl-info-line input').change(
				function() {
					$(this).removeClass('with-error');
				}
			);

			app.assignNumberControl();

            var $form = $('form.order-form');
            var $codes = $form.find('textarea.gl-code-info');

            if ($codes.length > 0) {
                $sel.val($codes.length).change();
			}

			$codes.each(
                function() {
                    var $this = $(this);
                    var name = $this.attr('name');
                    var pos = String(name).match(/\d+/)[0];
                    var $line = $('.sms-gl-line-' + pos);
                    var tmp = $this.val().split('; ');
                    var items = ['gl-code', 'gl-branch', 'gl-dep', 'gl-prc'];
                    for (var i = 0; i < tmp.length; i++){
                        var info = tmp[i].split(': ')[1];
                        $line.find('.' + items[i]).val(info);
                    }
                }
            );
		},
        initSurvey: function(url, quest, surveyURL) {
            var $links = $("a.button.checkout-cart");
            if (app.getLData('_surveyIsDone') !== 'Y') {
                $links.click(
                    function() {
                        app.askAboutSurvey(url, quest, surveyURL);
                    }
                );
            } else {
                $links.attr('href', url);
            }
        },
        askAboutSurvey: function (url, surveyURL) {
            app.confirmMsg(
                '_Survey_Question',
                function() {
                    setTimeout(
                        function() {
                            app.setLData('_surveyIsDone', 'Y');
                            var dialogParams = {
                                okTitle: 'Close',
                                cssClass: 'fullMode wideWnd',
                                appendToSelector: '._'
                            };
                            app.dialog(
                                '<div class="surveyWnd height-correctable">' +
                                '<iframe frameborder="no" src="' + surveyURL + '">' +
                                '</div>',
                                function() {
                                    if (url !== '') {
                                        window.location = url;
                                    }

                                },
                                dialogParams
                            );

                            $('#udu-div-window').find('button.close').click(
                                function() {
                                    if (url !== '') {
                                        window.location = url;
                                    }
                                }
                            );
                        },
                        700
                    )
                },
                function() {
                    app.setLData('_surveyIsDone', 'Y');
                    window.location = url;
                }
            );
        },
		checkIniLang: function() {
            var lKey = 'udu__ini-lang-done';
            var lCss = 'udu__ini-lang-checked';
			var langIsSet = this.getLData(lKey);
			if (langIsSet === 'Y') {
				$('html').addClass(lCss);
			} else {
				setTimeout(
					function() {
                        app.setLData(lKey, 'Y');
					},
					10000
				);
				app.ip.getIpInfo(
					function(data) {
						var regs = {
							'quebec': 1
						};
						if (!data) {
							data = {};
						}
						// in case we did not get any region we work as alberta
						if (!data.region) {
							data.region = 'alberta';
						}

						if (regs[data.region.toLowerCase()]) {
							if (app.getLang() === 'en') {
                                app.setLData(lKey, 'Y');
								app.lang.switch();
							} else {
								$('html').addClass(lCss);
                                app.setLData(lKey, 'Y');
							}
						} else {
                            $('html').addClass(lCss);
                            app.setLData(lKey, 'Y');
						}
					}
				)
			}
		},
        doAfterInventoryItemSet: function() {
			$('.option-name .qty-available').addClass('disp');
		},
		fixSortSelect: function() {
            $('.product-list-sort-menu select').find('option[value*="pr_s=sku_"]').remove();
		},
        buildPaginFilter: function() {
			if (app._vars.filterSubCats && app._vars.filterSubCats.length > 0) {
				var html = '<div class="category-filter"><label>' + app.lang.selectWord('Filter by', 'Filtrer par') + ':</label><select><option></option>';
                app._vars.filterSubCats.forEach(
                	function(arr) {
                        html += '<option value="' + arr.id + '">' + arr.name + '</option>';
					}
				);
				html += '</select></div>';
                $('.product-list-sort-menu').before(html);
                $('.category-filter select').change(
                	function() {
                		var id = $(this).val();
                        app._vars.filterSubCats.forEach(
                            function(arr) {
                            	if (arr.id === id) {
                            		window.location = arr['url'];
								}
                            }
                        );
					}
				);
			}
			var $pag = $('.pagination-menu');
			if ($pag.find('a').length > 6) {
                $pag.addClass('visible')
			}
            $('.product-list-top-part').addClass('_done');
			if (app._vars.filterCatId) {
				$('.category-filter select').val(app._vars.filterCatId);
			}
		},
		initBMO: function() {
			$('.top .quick-search input').focus(
				function() {
					$('.top .li-search')
						.addClass('selected');
				}
			);
			$('#not-cat-submit').click(
				function(e) {
                    app.sendInfo('NC');
					e.preventDefault();
				}
			);
            $('.top .li-search')
                .click(
                    function(e) {
                      e.stopPropagation();
                    }
                );
			$('body, .menu').click(
				function () {
                    $('.top .li-search')
						.removeClass('selected');
                }
			);
			$('.div-cart-table td.description').append('<a class="lnk-remove">' + (app.getLang() === 'en' ? 'Remove' : 'Retirer') + '</a>');
            $('a.lnk-remove').click(
                function(e) {
                    $(this).closest('tr').find('.del-form button').click();
                    e.preventDefault();
                }
            );
            var $specInstr = $('.special-instr textarea');
            if ($specInstr.length === 1) {
                $specInstr.keyup(
                    function() {
                        app.setLData('_specialInstr', $(this).val(), true);
                        app.createCookie('SpecialInstr', $(this).val());
                    }
                );
                var specInstr = app.getLData('_specialInstr', true);
                if (specInstr) {
                    $specInstr.val(specInstr);
                }
			}

			var $tbl = $('.part-2 .ch-prod-list');
            if ($tbl.length === 1) {
                $tbl.find('.att-link').addClass('min-mode');
                $tbl.find('.image .att-link iframe,.image .att-link img').wrap('<div class="att-wrapper"></div>');
            	$tbl.find('tr').each(
            		function() {
            			var $tr = $(this);
            			var $tdq = $tr.find('td.quantity');
            			if ($tdq.length === 1) {
            				$tr.find('.image li.att-link:first-child').append('<span class="qty">' + $tdq.html() + '</span>');
						}
					}
				)
			}
			var $form = $('form.order-form');
            if ($form.length === 1 && app.getLData('_specialInstr', true)) {
            	$form.append('<textarea class="hidden" name="custom[SpecialInstructions]" id="special-instr"></textarea>');
            	$('#special-instr').val(app.getLData('_specialInstr', true));
			}

			var $comment = $('.bestSellComments');
            if ($comment.length >= 1) {
            	var html = $comment.html().replace(/<a id="/g, '<span id="').replace(/<\/a>/g, '</span>');
                $comment.html(html);
                setTimeout(
                	function() {
                       $comment.addClass('_done');
					},
					600
				)
			}
			var $qtyItem = $('#pr_form_id_quantity');
            if ($qtyItem.length === 1) {
            	if (app.getLang() === 'en') {
            		var minMsg = 'The quantity you have entered is less than the minimum allowed to purchase.';
				} else {
                    minMsg = 'La quantité que vous avez entrée est moins de minimum autorisé à acheter.';
				}
                $qtyItem.data('udu-min-qty-msg', minMsg);
			}

			app.addEmailChecking();
		},
        doBeforeSetAvailableQuantity: function() {
			if (!app._vars.productInitIsDone) {
				return false;
			} else {
				return true;
			}
		},
        moveInventory: function() {
			var $prodInv = $('.product-inventory');
			if ($prodInv.length === 0) {
				return false;
			}
			var html = $prodInv.html();
            $prodInv.remove();
            $('div.submit').after('<div class="product-inventory qty-place">' + html + '</div>');
            return true;
		},
		contactUs: function() {
            app.sendInfo('US');
			return false;
		},
		wrapMultiInputs: function() {
			$('.mult-quantity input.number.text, .div-cart-table .tbl-bordered td input[name=quantity]')
				.wrap("<div class='mult-input-wrp div-dummy'></div>");

			$('.mult-input-wrp')
				.prepend('<button class="custom-button btn-minus btn-quant" type="button">-</button>')
				.append('<button class="custom-button btn-plus btn-quant" type="button">+</button>');

            $('.option-name > div').append('<div class="dv-tlt-qty">' + app.lang.selectWord('Quantity', 'Quantité') + '</div>');

            $('.div-cart-table td .mult-input-wrp input').change(
            	function() {
            		app.doCartRecalc($(this).closest('form'));
				}
			);
            $('.div-cart-table td .mult-input-wrp button').click(
                function() {
                    app.doCartRecalc($(this).closest('form'));
                }
            );
		},
		doCartRecalc: function($form) {
            $form.submit();
		},
        doCustomRecalcProcessing: function(form) {
			setTimeout(
				function() {
					var $form = $(form);
					$form.addClass('in-progress');
                    $.post(
                        $form.attr('action'),
                        $form.serialize(),
						function (html) {
							var tblHtml = html.split('<!--_UCTBL~-->')[1];

							$('._').append('<div id="_UCTBL-SYSTEM" class="hidden"></div>');
							var $div = $('#_UCTBL-SYSTEM');
							$div.html(tblHtml);
							var total = $div.find(' td[colspan=5] .money').html();
							var $tbl = $('.div-cart-table > table');
							$tbl.find(' td[colspan=5] .money').html(total);
							$tbl.find('form.del-form').each(
								function() {
									var $this = $(this);
									var id = $this.find('input[name=cartItemKey]').val();
									var val = $div.find('input[value=' + id + ']').closest('tr').find('td.subtotal span.money').html();
                                    $this.closest('tr').find('td.subtotal span.money').html(val);
								}
							);
							$div.remove();
							$form.removeClass('in-progress');
                        }	
					);	
				},
				200
			);
			return false;
		},
        doAfterPrevNextButtonsDone: function() {
			var $btnPlace = $('.prod-nav-buttons').addClass('custom-process _done');
            $('.prod-nav-buttons a:not(.prod-list)')
				.mousemove(
					function() {
						clearTimeout(app._vars.afterButtonsTimeOut);
                        $btnPlace.removeClass('custom-process');
					}
				);

            $btnPlace.mouseleave(
				function() {
					app._vars.afterButtonsTimeOut = setTimeout(
						function() {
                            $btnPlace.addClass('custom-process');
						},
						500
					);
				}
			);
		},
        initCustomOrders: function(url) {
			app._vars.sendMailUrl = url;
			$('.custom-blue li').click(
				function() {
                    var $this = $(this);
                    $this.toggleClass('selected');
                    var id = $this.data('id');
                    if (id === 'pt-OTHER') {
                    	var $inp = $this.find('input');
                    	if ($inp.length === 0) {
                    		$this.append('<div class="input-wrapper"><input maxlength="200" placeholder="' + app.lang.selectWord('Enter Product Type...', 'Entrez le type de produit...') + '"></div>');
                            $inp = $this.find('input');
                            $inp.click(
                            	function(e) {
                            		if ($(this).closest('li').hasClass('selected')) {
                                        e.stopPropagation();
									}
								}
							)
						}
						if ($this.hasClass('selected')) {
                            app.utils.effects.showIt($inp);
						}
					}
				}
			);
            $('.custom-blue .item').each(
				function() {
					var $this = $(this);
					var pos = app._getOrderPos($this);
					$this.find('.buttons button').click(
						function() {
							app.goToCustomOrder(pos + 1);
						}
					);
				}
			);
			$('.custom-order-navi li').click(
				function() {
					var pos = app._getOrderPos($(this));
					app.goToCustomOrder(pos);
				}
			);
            $('.custom-order-navi li.pos-0').addClass('selected');
            $(window).scroll(
            	function() {
					app._scrollNavi();
				}
			);
            $('.custom-order-navi').addClass('_done');
            app._scrollNavi();
		},
		_scrollNavi: function() {
            var h = $(window).scrollTop() + 110 + parseInt($('body').css('margin-top'));
            var sDiv = $(document).height() - $(window).scrollTop() - $(window).height();
      		var $items = $('.custom-blue .item');
            if (sDiv > 230) {
                var pos = 0;
                $items.each(
                    function () {
                        var $item = $(this);
                        var t = $item.offset().top;
                        if (t <= h) {
                            pos = app._getOrderPos($item)
                        }
                    }
                );
			} else {
            	pos = $('.custom-blue .item').length - 1;
			}

            app._markNaviItem(pos);
		},
		_getOrderPos: function($item) {
			var tmp = String($item[0].className).match(/pos-(\d)/);
			if (tmp && tmp[1]) {
				return Number(tmp[1]);
			}
			return 0;
		},
		_markNaviItem: function(pos) {
            $('.custom-order-navi li.selected')
                .removeClass('selected');

            $('.custom-order-navi li.pos-' + pos).addClass('selected');
		},
        initProduct: function() {
			var $opImgs = $('.product-images.options ul li');
			var $ul = $('.product-images-block ul.ul-prod-img');
			if ($opImgs.length > 0) {
                $ul.html($ul.html() + $('.product-images.options ul').html());
            }
			if ($ul.find('li').length > 0) {
                $('.product-images-block').uduSlider({
                    $item: $ul
                });
			}
		},
        sendInfo: function(mode) {
			if (!mode) {
				mode = 'B';
			}
			var email = String($('#email').val()).replace(/ /g, '');
			var phone = String($('#phone').val());
			var name = String($('#name').val());
			var msg = String($('#message').val());

			if ($('#can_funds').attr('checked')) {
				var canFunds = 'Yes';
			} else {
                canFunds = 'No';
			}
			if ($('#us_funds').attr('checked')) {
				var usFunds = 'Yes';
			} else {
                usFunds = 'No';
			}
			if (mode === 'US') {
                if (!name || !email || !msg) {
                	var errMsg = app.lang.selectWord(
                		'You did not fill all required fields. Please enter values into Name, Email and Message fields and try again',
						'Vous n\'avez pas rempli tous les champs obligatoires. Veuillez entrer votre nom, courriel et message et essayez à nouveau.'
					);
                    app.alert(errMsg);
                    return false;
                }
			} else if (mode === 'NC') {
                if (!phone || !email || (canFunds === 'No' && usFunds === 'No')) {
                    app.alertMsg('_Please_Enter_Value_Email_Phone_Msg_Req');
                    return false;
                }
			} else {
                if (!phone && !email) {
                	app.alertMsg('_Please_Enter_Value_Email_Phone_Msg');
                    return false;
                }
			}

			var data = {
				email: email,
				msg: msg,
				name: name,
				phone: phone,
				mode: mode,
				canFunds: canFunds,
				usFunds: usFunds,
				items: {

				}
			};
			var pos = 0;
			$('.custom-blue .item').each(
				function() {
					var $this = $(this);
					var tlt = $this.find('h4').html();
					if (tlt) {
						data.items[pos] = {
							tlt: tlt,
							vals: {

							},
							txt: ''
						}
					}

					var num = 0;
					$this.find('li.selected').each(
						function() {
							var $this = $(this);
							var $inp = $this.find('input');
							if ($inp.length === 1 && $inp.val() !== '') {
                                data.items[pos]['vals'][num] = $inp.val();
							} else {
                                data.items[pos]['vals'][num] = $this.find('span').html();
							}
							num++;
						}
					);
					var $txt = $this.find('textarea');
					if ($txt.length === 1) {
                        data.items[pos]['txt'] = $txt.val();
					}
					pos++;
				}
			);
			app.blockWindow(
				true,
				true,
				function () {
					$.post(
						app._vars.sendMailUrl,
						{
							data: JSON.stringify(data)
						},
						function(html) {
							app.alertMsg(
								'_Bmo_Thank_You_Msg',
                                function() {
                                    window.location.reload();
                                }
							);
						}
					);
				}
			);

			return false;
		},
		goToCustomOrder: function(pos) {
			var m = parseInt($('body').css('margin-top'));
			var t = $('.custom-blue .pos-' + pos).offset().top;
            $('body, html').animate({scrollTop: t - m - 20});
			app._markNaviItem(pos);
		},
        showSvgEditor: function() {
			for (var i = 0; i < 100; i++) {
				var $item = $('#attachment-' + i);
				if ($item.length === 1) {
                    svgEditor.callEditor(i, app._vars.svgMode);
                    break;
				}
			}
        },
        doAfterAttachSVGLoaded: function(idx) {
			if ($('.svg-btn-customize').length > 0) {
				return false;
			}
			if (this.getLang() === 'en') {
				$('.fld-svg').after('<button class="svg-btn-customize" onclick="app.showSvgEditor()" type="button">Customize</button>')
			} else {
				$('.fld-svg').after('<button class="svg-btn-customize" onclick="app.showSvgEditor()" type="button">Personnaliser</button>')
			}
        },
		markProductsWithIcons: function() {
			var $prods = $('.jcarousel-skin-index li .name, .product-list div.item .name, .product-view');
			if ($prods.length === 0) {
				return false;
			}
            $prods.before('<div class="bmo-icons"></div>');
			var key = '_CATS_2_PRODS';
			var savedProducts = app.getLData(key, true);
			if (!savedProducts || window.admin) {
				app.getInfo(
					'prods-by-attrs',
					{
						cats: '_DIVERSE_VENDOR|_BEST_SELLER|_ACCESSIBLE_PRODUCTS|_ENV_FRIENDLY|_BRAND_ON_DEMAND|_COMING_SOON'
					},
					function(data) {
						app._assigIcons(app.setLData(key, JSON.parse(data), true));
					}
				)
			} else {
				app._assigIcons(savedProducts);
			}
		},
		_assigIcons: function (data) {
			var items = {};
			if (app.getLang() === 'en') {
                var titles = {
                    _COMING_SOON: 'Coming Soon',
                    _BRAND_ON_DEMAND: 'Brand on Demand',
                    _BEST_SELLER: 'Best Seller',
                    _DIVERSE_VENDOR: 'Diverse Vendor',
                    _ACCESSIBLE_PRODUCTS: 'Accesible Product',
                    _ENV_FRIENDLY: 'Environmentaly Friendly'
                };
			} else {
                titles = {
                    _COMING_SOON: 'Disponible Bientot',
                    _BRAND_ON_DEMAND: 'Marque sur demande',
                    _BEST_SELLER: 'Produit le plus en demande',
                    _DIVERSE_VENDOR: 'Divers vendeurs',
                    _ACCESSIBLE_PRODUCTS: 'Produit accessible',
                    _ENV_FRIENDLY: 'Environmentaly Friendly'
                };
			}

			var prodId = null;
			if (app.prodInfo) {
                prodId = app.prodInfo.id;
			}
			for (var i in data) {
				var tmp = data[i].split(',');
                tmp.forEach(
                	function(val) {
                		if (!Number(val)) {
                			return true;
						}
                        if (Number(val) === Number(prodId)) {
                            $('.param-ed2-addOptions .' + i)
                                .addClass('active');
                        }
                		if (!items[val]) {
                			items[val] = $('.name a[href$="-' + val + '\\.html"]');
						}
                        items[val].each(
                        	function() {
                        		var $li = $(this).closest('li.item, div.item').addClass('bmo-product');
                        		var $div = $li.find('.bmo-icons');
								$div.append('<span title="' + titles[i] + '" class="' + i + '"></span>');
							}
						)
					}
				)
			}

			if (prodId) {
				setTimeout(
					function() {
                        if ($('.param-ed2-addOptions ._BRAND_ON_DEMAND.active').length >= 1) {
                            $('.product-submit-comment.com-1').addClass('disp');
                        } else {
                            $('.product-submit-comment.com-2').addClass('disp');
                        }
                        if (app.prodInfo.minQty > 1) {
                            var $com = $('.product-submit-comment.com-3');
                            if ($com.length > 0) {
                                var html = $com.html();
                                html = html.replace('%QTY%', app.prodInfo.minQty);
                                $com.html(html).addClass('disp');
							}
                        }
                        app._vars.productInitIsDone = true;
                        app.moveInventory();
                        var $comSoon = $('div._COMING_SOON.active');
                        if ($comSoon.length === 1) {
                            var $prodQntAlt = $('.product-quantity-alternative');
                            if ($prodQntAlt.length === 0) {
                                $('.product-inventory').append('<div class="product-quantity-alternative">' + $comSoon.html() + '</div>')
                            }
                        }

                        app.setProductAvailableQuantity();
                        if (window.admin) {
                            admin.assignEditor();
						}
					},
					100
				);
			}
        },
        doAfterProdListAttributesDone: function($list, savedData) {
			app.getAllJSON(
				function(data) {
					if (data.udu_displayProdQuantityInfo !== 'N') {
                        $list.each(
                            function() {
                                var $this = $(this);
                                var $item = $this.closest('.item');
                                var prodId = app.prodListAttrs.getIdByHref($this.attr('href'));
                                if (savedData[prodId] && savedData[prodId]['_minQty']) {
                                    var minQty = savedData[prodId]['_minQty'];
                                    var msg = '';
                                    if (minQty > 1) {
                                        if (app.getLang() === 'en') {
                                            msg = 'Minimum Quantity ' + minQty;
                                        } else {
                                            msg = 'Quantité minimum ' + minQty;
                                        }
                                        $item.append('<div class="min-order-line">' + msg + '</div>')
                                    } else {
                                        if (app.getLang() === 'en') {
                                            msg = 'No minimum quantity';
                                        } else {
                                            msg = 'Pas de quantité minimum';
                                        }
                                        $item.append('<div class="min-order-line no-limit">' + msg + '</div>')
                                    }
                                }
                            }
                        );
					}
                    setTimeout(
                        function() {
                            $('.product-list2 .item').addClass('attr-done');
                        },
                        500
                    );
				}
			)
        },
        doAfterBootStrapLoginCorrected: function() {
           setTimeout(
				function() {
					app.addEmailChecking();
					$('.bootstrap').addClass('_done');
				},
			   500
		   )
		}

	};
    app.checkEmailByDomain = function (email) {
    	if (!app._vars.regEmailDomain) {
    		return true;
		}
        var domains = [];
        app._vars.regEmailDomain.forEach(
        	function(domain) {
                if (domain) {
                    domains.push(domain);
                }
			}
		);

        if (!domains || domains.length === 0 || !email) {
            return true;
        }

        if (app._vars._reg_Incorrect_Domain_Extra) {
        	var tmp = app._vars._reg_Incorrect_Domain_Extra.split(',');
        	tmp.forEach(
        		function(domain) {
					domain = domain.replace(/ /g, '');
					if (domain) {
						domains.push(domain);
					}
				}
			)
		}

        var isValid = false;
        domains.forEach(
            function(domain) {
                if (String(email).endsWith(domain)) {
                    isValid = true;
                    return false;
                }
            }
        );
        return isValid;
    };

    app.addEmailChecking = function() {
    	var $items =  $('input[name="login"], input.ch-domain');
    	if (!app._usrLogged) {
            $items.val('');
		}
        app.getAllJSON(
            function(data) {
                var emailDomain = data['udu_regEmailDomain'];
                if (emailDomain) {
                    var tmp = emailDomain.split(',');
                    var domains = [];
                    tmp.forEach(
                        function(emailDomain) {
                            var domain = emailDomain.replace(/ /g, '').split('@').slice(-1).pop();
                            if (domain) {
                                domains.push('@' + domain);
                            }
                        }
                    );
                    if (domains.length > 0) {
                        app._vars.regEmailDomain = domains;
                        var placeHolder = app._vars.regEmailDomain.join(', ');
                        var hint = placeHolder;
                        if (domains.length === 1 && placeHolder.substr(0, 1) === '@') {
                            hint = 'user' + placeHolder;
                        }
                        $items.change(
							function() {
								var that = this;
								if (!app.checkEmailByDomain($(this).val())) {
									app.alertMsg(
										app._vars._reg_Incorrect_Domain_Mdg ? app._vars._reg_Incorrect_Domain_Mdg : '_reg_Incorrect_Domain_Mdg',
										function () {
											app.doFlicker($(that));
										},
										{
											replace:{'%DOMAIN%':  app._vars.regEmailDomain.join(', ')}
										}
									);
									app.doFlicker($(this));
								} else {
									var $tr = $('input[name="email"]').closest('tr').prev();
									if ($tr.hasClass('error-line')) {
										$tr.fadeOut(
											function() {
												$tr.remove();
											}
										)
									}
								}
							}
						)
                    }
                }
            }
        );
    };